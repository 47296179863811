@import '~bootstrap/scss/bootstrap.scss';

@media print {
  @page {
  }

  .download-report {
    display: none;
  }

  .report-header {
    width: 792px;
    height: 70px;
    margin-top: -10px !important;
    margin-bottom: 200px !important;

    background: #72c02c;
    color: white;
  }

  .report-table {
    margin-left: 40px;
    margin-right: 40px;
  }

  .text-head {
    margin-left: 10px;
  }

  thead {
    background: #4f4959 !important;
    color: white;
  }
}

.forgot-password-btn {
  background: none;
  border: none;
}

html,
body {
  height: 100%;
  font-family: 'Lato', sans-serif !important;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-30 {
  font-size: 1.875rem;
}

.white-text {
  color: #ffffff;
}

.forgot-password-btn:hover {
  text-decoration: underline;
}

.no-decoration {
  text-decoration: none;
}

.normal-link {
  color: #ffffff;
}

.normal-link:hover {
  color: #ffffff;
}

.no-decoration-button {
  border: none;
  background-color: none;
}

.pointer-cursor {
  cursor: pointer;
}

.header-title {
  font-size: 32px;
  display: flex;
  align-self: center;
}

.btn-primary {
  border-color: #6f42c1 !important;
  background-color: #6f42c1 !important;
}

.btn-primary:hover {
  background-color: #4d3096 !important;
}

.forgot-password-description-txt {
  font-size: 1.17rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.min-w-15 {
  min-width: 15vw;
}

.input-group-border {
  border: 1px solid #b3b3b3;
  border-radius: 0.5rem;
}

.outline-blue:focus-within {
  border: 2px solid #2684ff;
}

.outline-0:focus {
  outline: none !important;
  box-shadow: none !important;
}

.input-group {
  border-radius: 0.5rem;
  .form-control::placeholder {
    color: #6c757d;
  }
  .search {
    &:hover {
      color: #212529;
    }
    cursor: pointer;
    background-color: white;
    color: #6c757d;
  }
}

table {
  thead {
    --bs-table-bg: #f2e7fe !important;
  }
}

.btn-secondary {
  background-color: white !important;
  color: #6f42c1 !important;
  border-color: #6f42c1 !important;
}

.bottom-divider {
  border-bottom: solid black 1px;
  margin-bottom: 1rem;
}

.text-red {
  color: #dc3545;
}

.pill-add {
  border: 1px solid black;
  border-radius: 20px;
  button {
    background-color: white;
  }
}

.pill-remove {
  border: 1px solid #7f39fb;
  border-radius: 20px;
  background-color: #7f39fb;
  color: white;
  button {
    background-color: #7f39fb;
    color: white;
  }
}

.btn-light {
  background-color: white !important;
  color: #7f39fb !important;
  border: 1px solid #7f39fb !important;
}

.input-success {
  border-color: #7f39fb !important;
}

.input-error {
  border-color: #dc3545 !important;
}

.text-purple-light {
  color: $purple;
}
.booked {
  background: #ffb800 !important;
  color: black !important;
}

.type {
  display: none !important;
}

.dayOfTheWeek .day {
  background: white !important;
}

.month .content .day:nth-child(7n-1),
.month .content .day:nth-child(7n) {
  background: #e9ecef !important;
}

.day {
  background: #f8f9fa;
}

.calendar-card {
  border-width: 0 !important;
  border-radius: 0 !important;
  background: #f8f9fa !important;
}

.download-report {
  text-decoration: none;
  color: black;
  font-style: normal;
  font-weight: 700;
}

.download-report-container {
  display: flex;
  justify-content: right;
  width: 100%;
}

.download-report-container2 {
  display: flex;
  justify-content: right;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.footer {
  border-top: 1px solid #e9ecef;
}

.acklen-logo {
  height: 2rem;
  width: 12rem;
}

.rct-dateHeader-primary,
.rct-item {
  color: black !important;
}

.purple-badge {
  background: #4f4959;
  width: 165px;
  border-radius: 15px !important;
}

.gray-badge {
  background: #8e9296;
  width: 165px;
  border-radius: 15px !important;
}

.green-badge {
  background: #72c02c;
  width: 165px;
  border-radius: 15px !important;
}

.yellow-badge {
  background: #ffb800;
  width: 165px;
  border-radius: 15px !important;
}
