.modal-container {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 1rem 2rem;
  width: 80vw;
  max-width: 680px;
  min-height: 400px;
  height: auto;
  max-height: 630px;
  margin: auto;
  overflow: hidden;
}
