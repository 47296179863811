.card-button {
  width: 348px;
  height: 360px;
  border: 1px dashed $purple;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.circle-plus-button {
  color: white;
  background-color: $purple;
  border-radius: 50%;
  border: 1px solid $purple;
}

.timeoff-card {
  width: 348px;
  height: 360px;
  border: 1px solid $purple;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.timeline-sidebar-header {
  position: absolute;
  top: 1.2rem;
  p {
    text-align: center;
    width: 150px;
  }
}

.home-timeline {
  .react-calendar-timeline .rct-header-root {
    background-color: #f2e7fe;
  }

  .react-calendar-timeline,
  .rct-calendar-header,
  .rct-dateHeader,
  .rct-sidebar-row {
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;

    .header-today {
      background-color: #7f39fb;
      border: 1px solid #7f39fb;
      border-radius: 5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .weekday-span {
        color: white;
      }

      .day-span {
        color: white;
      }
    }
  }

  .rct-hl-even {
    border-bottom: none !important;
  }

  .rct-dateHeader {
    border-left: none !important;
  }

  .rct-sidebar {
    width: 151px !important;
  }

  .rct-day-0,
  .rct-day-6 {
    background-color: #dee2e6 !important;
  }

  .rct-item {
    height: 36px !important;
    background: #ffb800 !important;
    border: none !important;
    border-radius: 5px !important;
  }

  .rct-hl-odd {
    background: white !important;
    border-bottom: none !important;
  }

  .rct-outer {
    border: 1px solid #bbb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rct-header-root {
    border: 1px solid #bbb;
    border-bottom: none !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: sticky;
    top: 0;
    z-index: 150;
  }

  .month-chevron-left {
    visibility: hidden;
    float: left;
  }

  .month-chevron-right {
    visibility: hidden;
    float: right;
  }

  .month-info-interval {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .rct-dateHeader.month-interval.info:hover > .month-info-interval {
    overflow: visible !important;
    margin-left: -10px;
    margin-right: -10px;
    z-index: 1000;
    min-width: 120px;
  }

  .monthyear-info:hover {
    background-color: #d4afff;
  }

  .month-info:hover {
    background-color: #dcdcdc;
  }

  .month-interval:hover .month-chevron-right {
    visibility: visible;
    padding-left: 5px;
    padding-right: 5px;
  }

  .month-interval:hover .month-chevron-left {
    visibility: visible;
    padding-left: 5px;
    padding-right: 5px;
  }

  .month-info-span {
    margin: auto;
    font-size: 14px;
    text-align: center;
    transition: background-color 0.3s ease-in;
    width: auto !important;
  }

  .h-0 {
    height: 0;
  }

  .date-header-year .rct-dateHeader {
    background-color: #f2e7fe;
  }

  .monthyear-info {
    background-color: #f2e7fe;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 200;
}
