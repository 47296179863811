.tribe-org-report-type-header {
  width: 25%;
}

.tribe-org-report-type-subheader {
  display: flex;
  justify-content: space-between;
}

.report-members-header {
  width: 25%;
}