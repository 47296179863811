
.input-group {

  .form-control {
    border-right: none !important;
  }

  #right-icon {
    background-color: white;
    width: 2.5rem;
  }

}