.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    height: 38px;
  }

  .react-datepicker__close-icon {
    margin-right: 5px;
  }
}

.tippy-box {
  background-color: #00000099 !important;

  .item-tooltip {
    display: flex;
    flex-direction: column;
    padding: 5px;
    line-height: 2rem;

    .item-tooltip-title {
      font-weight: bold;
      font-size: 18px;
    }
  }
}

.current-state {
  font-size: 24px;
  color: #6C757D;
}


.dashboard-item {
  width: 30%;
  height: 200px;
  background-color: #F8F9FA;
  border-color: #DEE2E6;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;

  .title {
    font-weight: bold;
    font-size: large;
    padding-bottom: 6px;
  }

  .big-number {
    color: #4F4F4F;
    font-size: 48px;
  }

  .gray-color {
    color: #6C757D;
  }

  .tribe-item {
    background-color: white;
    padding-left: 20px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    min-width: 200px;
  }
}

.team-utilization-timeline {

  .react-calendar-timeline,
  .rct-calendar-header,
  .rct-dateHeader,
  .rct-sidebar-row {
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
  }

  .rct-dateHeader {
    background-color: white;
  }

  .rct-header-root {
    background-color: #f2e7fe;
    border: 1px solid #dee2e6;
    border-bottom: none !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .sidebar-header {
      background-color: white;
      font-weight: bold;
      font-size: large;
      border-right: 1px solid #ced4da !important;

      .sidebar-header-years {
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
      }

      .sidebar-header-months {
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .sidebar-header-weeks {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dee2e6;
      }
    }

    .rct-calendar-header {
      border-style: hidden;

      .rct-hl-even {
        border-bottom: none !important;
      }

      .rct-dateHeader {
        border-left: none !important;
      }

      .date-header-year {
        pointer-events: none;
        font-weight: bold;

        .rct-dateHeader {
          border-left: 1px solid #ced4da !important;
          border-bottom: 1px solid #ced4da !important;
        }
      }

      .date-header-month {
        pointer-events: none;
        font-weight: bold;

        .rct-dateHeader {
          border-left: 1px solid #ced4da !important;
        }
      }

      .date-header-week {
        pointer-events: none;
        font-weight: bold;

        .rct-dateHeader {
          border-left: 1px solid #ced4da !important;
          border-top: 1px solid #ced4da !important;
        }

        .this-week {
          background-color: #7f39fb;
          color: white;
        }
      }

      .date-header-day {
        pointer-events: none;

        .rct-dateHeader {
          display: flex;
          flex-direction: column;
          line-height: 1;

          font-weight: 200;
          font-size: 10px;

          &.header-sunday {
            border-left: 1px solid #ced4da !important;
          }

          &.header-today {
            background-color: #7f39fb;
            border: 1px solid #7f39fb;
            border-radius: 5px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;

            .weekday-span {
              color: white;
            }

            .day-span {
              color: white;
            }
          }

          .weekday-span {
            color: #adb5bd;
          }

          .day-span {
            color: #6c757d;
          }
        }
      }
    }
  }

  .rct-outer {
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: fit-content !important;

    .rct-sidebar {
      width: 150px !important;
      border-right: 1px solid #dee2e6;
    }

    .rct-scroll {
      overflow-x: hidden;
    }

    .rct-sidebar-row-odd {
      background-color: white !important;
    }

    .group-tribe {
      color: white;
    }

    .rct-vl {
      background-color: #f8f9fa !important;
      border-style: hidden;

      &.rct-day-0 {
        border-left: 1px solid #dee2e6;
      }
    }

    .rct-hl-even,
    .rct-hl-odd {
      border: 1px solid #dee2e6 !important;
      position: relative;
    }

    .rct-item {
      border: 0px solid #f8f9fa !important;
      border-radius: 5px !important;
      font-weight: bold;
      font-size: 14px !important;
      padding: 0px 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item-with-text-align {
      border: 0px solid #f8f9fa !important;
      border-radius: 5px !important;
      font-weight: bold;
      font-size: 14px !important;
      padding: 0px 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
}