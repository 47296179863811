.header {
  display: flex;
  background-color: $purple;
  height: 60px;
  .container {
    display: flex;
    justify-content: space-between;
    .navbar {
      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        margin: 0;
      }
      li {
        cursor: pointer;
        position: relative;
        display: inline;
        color: white;
        font-weight: 400%;
        .dropdown-link {
          color: white;
        }
        .dropdown-menu {
          a {
            color: black;
          }
        }
      }
      .selected {
        font-weight: 500%;
      }
      .selected::after {
        content: '';
        width: 60%;
        height: 4px;
        background: gold;
        position: absolute;
        bottom: -7px;
        left: 0;
      }
    }
    .logo {
      img {
        width: 80px;
        height: 60px;
      }
    }
  }
}
