.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-container {
  text-align: center;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
}

.loading-dots {
  animation: loading-dots-animation 1.5s infinite;
}

@keyframes loading-dots-animation {
  0%,
  20% {
    color: #333;
  }
  40% {
    color: #888;
  }
  60% {
    color: #ccc;
  }
  80%,
  100% {
    color: #eee;
  }
}
