.release-plans {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px; /* Adjust this value as needed */
    text-align: center;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .select-container {
    margin-bottom: 20px;
  }

  .select-board {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .generate-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .select-board:focus,
  .generate-button:focus {
    outline: none;
  }
}
