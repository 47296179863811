.tribe-org-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.tribe-org-tags-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.tribe-org-header-tag {
  margin: 5px;
  display: flex;
  align-items: center;
}

.tag-color {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  margin: 5px;
}

.tribe-org-manage-tribe-button {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 8px;
  background: #7f39fb;
  border-radius: 4px;
  color: white;
  border-color: transparent;
}

.tribes-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.tribe-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tribe-header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .tribe-logo {
    width: 48px;
    height: 48px;
    background: #f1aeb5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .letter {
      font-weight: 700;
      font-size: 25px;
      color: #ffffff;
    }
  }

  .tribe-title {
    width: auto;
    height: 36px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #212529;
    margin-left: 5px;
  }

  .tribe-last-update {
    width: auto;
    height: 19px;
    margin-top: 5px;
    margin-left: 5px;
    color: #6c757d;
  }
}

.tribe-header-right {
  width: auto;
  height: 19px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  .tribe-header-profile-view-link {
    position: absolute;
    width: 130px;
    height: 19px;
    left: 892px;
    top: 45px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #000000;
  }
}

.tribe-leadership-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 96px;
  background: #e9ecef;
  border-radius: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .tribe-leadership-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    padding: 12px;
  }

  .leadership-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #6c757d;
  }

  .lead-name {
    width: auto;
    height: 17px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #212529;
    margin-top: 5px;
  }
}

.tribe-projects-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tribe-project-container {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.leadership-team {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
}

.project-name-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  .project-badge {
    display: flex;
    align-items: center;
    padding: 4.2px 7.8px;
    width: 22.6px;
    height: 20.4px;
    background: #212529;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    color: white;
    margin: 10px;
  }

  .team-name {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 5px;
    line-break: anywhere;
    max-lines: 1;
    max-width: 32ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #6c757d;
  }

  .project-name {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 5px;
    line-break: anywhere;
    max-lines: 1;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.projects-date-range-container {
  width: 100%;
  background: #e9ecef;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c757d;
}

.date-range-start {
  border-right: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 50%;
  height: 54px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-range-end {
  border-left: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 50%;
  height: 54px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-text {
  margin: 10px;
}

.tribe-team-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-style {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px;
}
// ------------------------------------------------------------------------------------------

.team {
  width: 100%;
  height: auto;
  margin: 5px;
  border-radius: 4px;
}

.member-box {
  border-bottom: 1px solid white;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4.2px 7.8px;
  width: 90px;
  height: 20.4px;
  background: #4f4f4f;
  border-radius: 4px;
}

.Developer {
  background: #e2d9f3;
}

.Quality_Assurance {
  background: #ffe5d0;
}

.Designer {
  background: #d2f4ea;
}

.Developer_Operations {
  background: #e7f1ff;
  .badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4.2px 7.8px;
    width: 90px;
    height: 20.4px;
    background: #6c757d;
    border-radius: 4px;
  }
}

.no-team-message-container {
  width: auto;
  height: 19rem;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #adb5bd;
  flex-direction: column;
  justify-content: center;
}

.min-height {
  min-height: 80vh;
}

.tribe-carousel {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 68px;
    height: 68px;
    color: #7f39fb;
    opacity: 0.6;
    border-radius: 50%;
  }
  .carousel-control-prev,
  .carousel-control-next {
    top: 50%;
    bottom: 50%;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }

    &:focus {
      opacity: 0.9;
    }
  }

  .carousel-control-prev {
    left: -95px;
  }

  .carousel-control-next {
    right: -95px;
  }
}

.project-container-col {
  margin-left: 1rem;

  &:first-child {
    margin-left: 0rem;
  }
}

.tribe-org-button-week{
  background-color: white;
}

.tribe-org-filters{
  padding: 0 2%;
}

.tribe-org-week-indicator{
  font-weight: bold;
  margin-left: 2%;
}